import './App.css';
import { Auth, Storage, API } from 'aws-amplify';
import React, { useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import './christmas.css';
import { Container, Navbar } from 'react-bootstrap';
import logo from './internal_translation_logo.png';
import DarkMode from "./DarkMode";
import Fab from '@mui/material/Fab';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import TextField from '@mui/material/TextField';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MuiAlert from '@mui/material/Alert';
import { TranslateClient, TranslateTextCommand } from "@aws-sdk/client-translate";
import EastIcon from '@mui/icons-material/East';
import ChatIcon from '@mui/icons-material/Chat';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircularProgress from '@mui/material/CircularProgress';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { AnimatePresence, motion } from "framer-motion";
import pumpkin from "./pumpkin.png";
import Link from '@mui/material/Link';
import Nav from 'react-bootstrap/Nav';
import { useCookies } from 'react-cookie';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import ListItemText from '@mui/material/ListItemText';
import { pink } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import update_image from './slack1.JPG';
import ait_logo from './ait_pop.png'
import InfoIcon from '@mui/icons-material/Info';
import SendIcon from '@mui/icons-material/Send';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@material-ui/core/Paper'
import {allowedList} from "./libs/allowedList";
import * as TranscribeClient from "./libs/transcribeClient.js";
import HowtoUseDialog from "./libs/howtoUseDialog";
import slackImage from './slack_logo.png'

//import "./red.css";

import {
    detectOS,
    detectBrowser,
    getLanguageCode,
    createTranslateClientforTranscribe,
    TranscribeResultType
} from "./libs/transcribeClient.js";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Draggable from 'react-draggable';
import HowtoUseDialogMacOS from "./libs/howtoUseDialogMacOS";
import AWS from "aws-sdk";

function App() {
    const [user, updateUser] = useState({ userId: '', groups: [] });

    //christmas 
    var today = new Date();
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    if (mm == String(12)) {
        document.addEventListener('DOMContentLoaded', function () {
            let v = window.innerHeight / 60 + 2,
                h = window.innerWidth / 60 + 2,
                data = {
                    'top': h
                },
                c = null,
                ul = null;
            for (let position in data) {
                c = data[position];
                ul = document.createElement('ul');
                ul.className = 'christmas-lights';
                ul.dataset.position = position;
                for (let i = 0; i <= c; i++) {
                    ul.append(document.createElement('li'));
                }
                document.body.append(ul);
            }
        });
    }
    //halloween
    var chat = '';
    var dd = today.getDate();
    if (mm === String(10) && (23 <= dd && dd <= 31)) {
        chat = (<img src={pumpkin} width="48px" height="48px" />);
    }
    else {
        chat = (<ChatIcon />);
    }
    //authentication
    const parseAndUpdateUser = (cognitoUserData) => {
        const idToken = cognitoUserData.getSignInUserSession().getIdToken()
        const userId = idToken.payload['identities'][0].userId
        const groups = idToken.payload['custom:ldap'].split(',')
        groups.push(idToken.payload['custom:posix']);
        return updateUser({ userId: userId, groups: groups });
    }
    React.useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(cognitoUserData => {
                updateUser(cognitoUserData)
                const currentUser = String(cognitoUserData['username']).split('_')[1]
                console.log("current user is authenticated");
                if (checkInstantTranslationUsable(currentUser)) {
                    setButtonVisible(true);
                }
            })
            .catch(() => {
                console.log('No signed in user. sign in...')
                Auth.federatedSignIn({ customProvider: 'AmazonFederate' })
                console.log('Use AmazonFederate to login');
            });

    }, []);
    document.title = "Amazon Internal Translation (AIT)";

    const isWindows = detectOS() === "Windows";
    const username = String(user['username']).split('_')[1]
    console.log('current user is authenticated')

    //file upload
    const [value, setValue] = useState('translator');
    const [uploadResponse, setUploadResponse] = useState('');
    const [uploadSuccess, setUploadSuccess] = useState('');
    const [progress, setProgress] = useState('');
    const [fileName, setFileName] = useState("No file chosen");

    const [file, setFile] = useState({});
    async function onFileChange(e) {
        setFileName(e.target.files[0].name);
        setFile(e.target.files[0]);
    }


    async function onFileUpload(e) {
        if (file.name === undefined) {
            setUploadResponse('Please select file to upload')
            setFileName("No file chosen") //empty the file selected area
        }
        else if ((file.size / 1000000) > 20) {
            setUploadResponse('Upload Error: Please upload a file that is less than 20MB and 1 million characters.');
        }
        else if (fromLang === 'auto' || fromLang == undefined) {
            setUploadResponse('Warning! Please provide a "From" language above to ensure a better translation experience.');
        }
        else if (fromLang === toLang) {
            setUploadResponse('Warning! Please select a "To" language that is different from "From" language.');
        }
        else {
            if (value === 'translator') {
                if (['docx', 'pptx', 'xlsx'].includes(file.name.split('.').pop())) {
                    try {
                        const info = await Storage.put(value + "/" + username + "_" + fromLang + "_" + toLang + "_" + file.name.replace(/\s/g, '_').replace(/[^\p{L}\p{N}._]+/ug, ''), file, {
                            progressCallback(progress) {
                                console.log(`Uploaded: ${progress.loaded / 1000} KB / ${progress.total / 1000} KB`);
                                setProgress(`Uploaded: ${progress.loaded / 1000} KB / ${progress.total / 1000} KB`);
                            }
                        })
                        setUploadResponse('');
                        setUploadSuccess('File was uploaded successfully! We are actively translating your file and will send you an Email for download shortly. \n Translations can take 15 - 25 minutes to process.');
                        setFileName("No file chosen") //empty the file selected area
                    } catch (error) {
                        console.log('Error uploading file: ', error);
                        setUploadResponse('Error uploading file: ', error);
                        setFileName("No file chosen")
                    }
                }
                else {
                    console.log('not supported file');
                    setUploadResponse('Please choose files in these format: .docx, .pptx, .xlsx');
                    setFileName("No file chosen");
                }
            }
            else {
                if (file.name.split('.').pop() === 'csv') {
                    if (value !== '') {
                        if (file.name.split('_')[0] === username) { //check if username is included in file name
                            if (filenamelist.includes(file.name)) { //check if file is already in the bucket
                                handleShow() //show modal if there is already a file in bucket to check if user want to overwrite
                            } else {
                                try {
                                    const info = await Storage.put(value + "/" + file.name.replace(/\s/g, '_'), file, {
                                        progressCallback(progress) {
                                            console.log(`Uploaded: ${progress.loaded} bytes / ${progress.total} bytes`);
                                            setProgress(`Uploaded: ${progress.loaded} bytes / ${progress.total} bytes`);
                                        },
                                        contentType: 'txt/csv' // contentType is optional
                                    })
                                    setUploadResponse('');
                                    setUploadSuccess('File received. Will check the file and send an email with the status of upload!');
                                    setFileName("No file chosen") //empty the file selected area
                                } catch (error) {
                                    console.log('Error uploading file: ', error);
                                    setUploadResponse('Error uploading file: ', error);
                                    setFileName("No file chosen")
                                }
                            }
                        } else {
                            const filename = file.name
                            if (filenamelist.includes(username + "_" + file.name) || filenamelist.includes(username + "_" + file.name.replace(/\s/g, '_'))) { //check if file is already in the bucket
                                handleShow() //show modal if there is already a file in bucket to check if user wants to overwrite
                            } else {
                                try {
                                    const info = await Storage.put(value + "/" + username + "_" + file.name.replace(/\s/g, '_'), file, {
                                        progressCallback(progress) {
                                            console.log(`Uploaded: ${progress.loaded} bytes / ${progress.total} bytes`);
                                            setProgress(`Uploaded: ${progress.loaded} bytes / ${progress.total} bytes`);
                                        },
                                        contentType: 'txt/csv' // contentType is optional
                                    })
                                    setUploadResponse('');
                                    setUploadSuccess('File received with your username attached to the filename: ' + username + '_' + filename + 'Will check the file and send an email with the status of upload!');
                                    setFileName("No file chosen") //empty the file selected area
                                } catch (error) {
                                    console.log('Error uploading file: ', error);
                                    setUploadResponse('Error uploading file: ', error);
                                    setFileName("No file chosen")
                                }
                            }
                        }
                    } else {
                        setUploadResponse('Please choose a folder!');
                        setFileName("No file chosen")
                    }
                } else {
                    console.log('not csv file');
                    setUploadResponse('Please choose a CSV file!');
                    setFileName("No file chosen")
                }
                Storagelist(value); //refresh file list area
            }
        }
    }

    async function overwriteFile(e) {
        if (file.name.split('_')[0] === username) {
            try {
                handleClose()
                const info1 = await Storage.put(value + "/" + file.name.replace(/\s/g, '_'), file, {
                    progressCallback(progress) {
                        console.log(`Uploaded: ${progress.loaded} bytes / ${progress.total} bytes`);
                        setProgress(`Uploaded: ${progress.loaded} bytes / ${progress.total} bytes`);
                    },
                    contentType: 'txt/csv' // contentType is optional
                })
                setUploadResponse('');
                setUploadSuccess('File received. Will check the file and send an email with the status of upload!');
            } catch (error) {
                console.log('Error uploading file: ', error);
                setUploadResponse('Error uploading file: ', error);
            }
            setFileName("No file chosen") //empty the file name area
            Storagelist(value); //refresh file list area
            setIsChecked(!isChecked); //uncheck my uploads checkbox
        } else {
            const filename = file.name
            try {
                handleClose()
                const info1 = await Storage.put(value + "/" + username + "_" + file.name.replace(/\s/g, '_'), file, {
                    progressCallback(progress) {
                        console.log(`Uploaded: ${progress.loaded} bytes / ${progress.total} bytes`);
                        setProgress(`Uploaded: ${progress.loaded} bytes / ${progress.total} bytes`);
                    },
                    contentType: 'txt/csv' // contentType is optional
                })
                setUploadResponse('');
                setUploadSuccess('File received with your username attached to the filename: ' + username + '_' + filename + 'Will check the file and send an email with the status of upload!');
            } catch (error) {
                console.log('Error uploading file: ', error);
                setUploadResponse('Error uploading file: ', error);
            }
            setFileName("No file chosen") //empty the file name area
            Storagelist(value); //refresh file list area
        }
    }

    //File list
    var moment = require("moment");
    const [fileslist, getFiles] = useState([]);
    let filenamelist = [];
    var checks = new Object();
    const num_files = fileslist.length
    fileslist.forEach((files) => {
        if (files.key.slice(-3) == 'csv') {
            filenamelist.push(files.key.split('/')[2])
        }
    })

    function Storagelist(e) {
        setValue(e)
        if (e.includes('cleaned')) {
            Storage.list(e + "/") // for listing ALL files without prefix, pass '' instead
                .then(result => getFiles(result))
                .catch(err => console.log(err))
        }
        else {
            Storage.list("cleaned/" + e + "/") // for listing ALL files without prefix, pass '' instead
                .then(result => getFiles(result))
                .catch(err => console.log(err))
        }
    }
    //check box status management
    const [isChecked, setIsChecked] = useState(false);
    const handleOnChange = () => {
        setIsChecked(!isChecked);
        if (isChecked == false) {
            Storage.list("cleaned/" + value + "/" + username) // for listing ALL files without prefix, pass '' instead
                .then(result => getFiles(result))
                .catch(err => console.log(err))
        } else {
            Storagelist(value)
        }
    };

    //File upload Modal
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setFileName("No file chosen")
    }
    const handleShow = () => setShow(true);

    //File delete Modal
    const [Deleteshow, setDeleteShow] = useState(false);
    const handleDeleteClose = () => {
        setDeleteShow(false);
    }
    const handleDeleteShow = () => {
        setDeleteShow(true);
    }

    //File Download
    async function onFileDownload(e, files) {
        const signedURL = await Storage.get(files.key, { expires: 60 });
        const link = document.createElement('a');
        link.href = signedURL;
        link.click()
    }
    //File Delete
    const [deletingfile, setDeletingFile] = useState('')
    const [deletingOriginalfile, setDeletingOriginalFile] = useState('')
    async function onFileDelete(e, files) {
        setDeletingFile(files.key);
        setDeletingOriginalFile(files.key.substring(8)) //delete this line in the future so it won't delete original
        handleDeleteShow()
    }

    async function deleteFile() {
        await Storage.remove(deletingfile);
        await Storage.remove(deletingOriginalfile);
        Storagelist(value);
        setIsChecked(!isChecked); //uncheck my uploads checkbox
        handleDeleteClose();
    }
    let userPicture = 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + username;

    //search bar
    const [searchInput, setSearchInput] = React.useState("")
    const [selected, setSelected] = useState({});
    const handleSelect = (key, event) => {
        setSelected({ key, value: event.target.value });
    };
    const searchhandleChange = (e) => {
        e.preventDefault();
        search(searchInput);
    };

    const [folder, setFolder] = React.useState('');

    const handleChange = (event) => {
        setFolder(event.target.value);
    };
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '0px solid #ced4da',
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
        },
    }));

    async function search(search_text) {
        let search_result = [];
        if (search_text.length > 0) {
            if (folder === '') {
                await Storage.list("cleaned/") // for listing ALL files without prefix, pass '' instead
                    .then(result => result.forEach(searchfiles => search_result.push(searchfiles)))
                    .catch(err => console.log(err))
            }
            else if (folder !== undefined) {
                await Storage.list("cleaned/" + folder + "/") // for listing ALL files without prefix, pass '' instead
                    .then(result => result.forEach(searchfiles => search_result.push(searchfiles)))
                    .catch(err => console.log(err))
            }
        };
        let filtered_search = search_result.filter(str => str.key.toLowerCase().includes(search_text.toLowerCase()));
        getFiles(filtered_search)
        setValue("your search")
    }

    //list all my uploads
    async function listAllMine(search_text) {
        let search_result = [];
        await Storage.list("cleaned/") // for listing ALL files without prefix, pass '' instead
            .then(result => result.forEach(searchfiles => search_result.push(searchfiles)))
            .catch(err => console.log(err))
        let filtered_search = search_result.filter(str => str.key.split('/')[2].split('_')[0].includes(username));
        getFiles(filtered_search)
        setValue("All files uploaded by " + username)
    }

    //user_picture dropdown
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const userPictureClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const userPictureClose = () => {
        setAnchorEl(null);
    };

    //FAB
    const [fabAnchor, setfabAnchor] = React.useState(null);
    const fabOpen = Boolean(fabAnchor);
    const userFabClick = (event) => {
        setfabAnchor(event.currentTarget);
    };
    const userFabClose = () => {
        setfabAnchor(null);
    };
    const theme = createTheme({
        palette: {
            submit: {
                main: '#64748B',
                contrastText: '#ff6666',
            },
            upload: {
                main: '#008080',
                contrastText: '#ffe4e1',
                dark: '#ff6666'
            },
            field: {
                main: '#e7f9f0',
                dark: '#ff6666'
            }
        },
    });

    //Send message function
    const fabStyle = {
        position: 'fixed',
        bottom: '15px',
        right: '15px'
    };
    const [messageInput, setMessageInput] = React.useState("");
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState("");
    const [snackSeverity, setSnackSeverity] = React.useState("success")
    var jsonData = {
        'from': username,
        "message": messageInput
    }
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleSnackClick = () => {
        if (messageInput.length === 0) {
            setSnackSeverity("error")
        }
        else {
            setSnackSeverity("success")
        }
        setSnackOpen(true);
    };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };
    const snackAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="snak-close"
                color="inherit"
                onClick={handleSnackClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    function sendMessage(e) {
        if (messageInput.length > 1) {
            const slackUrl = process.env.REACT_APP_SLACK_HOOK || "EmptySlackUrl";
            fetch(slackUrl,
                {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify(jsonData)
                }
            )
            setfabAnchor(null);
            setSnackMessage('Message Sent! Our team will check the message')
            handleSnackClick();
        }
        else {
            setSnackMessage('Please enter feedback!');
            handleSnackClick();
        }
        setMessageInput("");
    }
    //real time translation box
    const [cookies, setCookie, removeCookie] = useCookies(['fromLast', 'toLast','fromDefault', 'toDefault', 'fromCheck', 'toCheck', 'updateAlert', 'alertShowTime', 'alertFirstShowTime', 'dontShowAgain']);

    // if (cookies.fromDefault == undefined) {
    //     var defaultFromLang = 'auto'
    // } else {
    //     var defaultFromLang = cookies.fromDefault
    // }
    // if (cookies.toDefault == undefined) {
    //     var defaultToLang = 'ja'
    // } else {
    //     var defaultToLang = cookies.toDefault
    // }
    var defaultFromLang = cookies.fromDefault
    var defaultToLang = cookies.toDefault
    const [fromLang, setFromLang] = React.useState(defaultFromLang ?? cookies.fromLast ?? "auto");
    const [toLang, setToLang] = React.useState(defaultToLang ?? cookies.toLast ?? "ja");


    if ((cookies.fromCheck == undefined) || (cookies.fromDefault != fromLang)) {
        var defaultFromCheck = false
    }
    else {
        var defaultFromCheck = true
    }
    if (cookies.toCheck == undefined || cookies.toDefault != toLang) {
        var defaultToCheck = false
    }
    else {
        var defaultToCheck = true
    }

    const defaultFromChange = (event) => {
        if (fromLang == undefined || fromLang != defaultFromLang) {
            removeCookie('fromDefault')
            setCookie('fromDefault', fromLang, { path: '/', maxAge: 2147483647 })
            setCookie('fromCheck', true, { path: '/', maxAge: 2147483647 })
            setFromCheck(event.target.checked)
        }
        else {
            removeCookie('fromDefault')
            setCookie('fromCheck', false, { path: '/', maxAge: 2147483647 })
            setFromCheck(event.target.checked)
        }

    };

    const defaultToChange = (event) => {
        if (toLang == undefined || toLang != defaultToLang) {
            removeCookie('toDefault')
            setCookie('toDefault', toLang, { path: '/', maxAge: 2147483647 })
            setCookie('toCheck', true, { path: '/', maxAge: 2147483647 })
            setToCheck(event.target.checked)
        } else {
            removeCookie('toDefault')
            setCookie('toCheck', false, { path: '/', maxAge: 2147483647 })
            setToCheck(event.target.checked)
        }

    };

    const [InputText, setInputText] = React.useState('');
    const [OutputText, setOutputText] = React.useState('');
    const [fromcheck, setFromCheck] = React.useState(defaultFromCheck);
    const [tocheck, setToCheck] = React.useState(defaultToCheck);

    const FromLanghandleChange = (event) => {
        setFromLang(event.target.value);
        if ((cookies.fromCheck == undefined) || (cookies.fromDefault != event.target.value)) {
            setFromCheck(false)
        }
        else {
            setFromCheck(true)
        }

        if (voiceTranStarted) {

            TranscribeClient.stopRecording();
            const currentFromLang = getLanguageCode(event.target.value);
            const currentToLang = getLanguageCode(toLang);
            voiceInputDisplayedRef.current = textInput.current.value;
            TranscribeClient.startRecordingSpeaker(creds, currentFromLang, currentToLang, withTranscriptionDataReceived);
        }

    };
    const ToLanghandleChange = (event) => {
        setToLang(event.target.value);
        console.log(cookies.toCheck)
        console.log(cookies.toDefault)
        if (cookies.toCheck == undefined || cookies.toDefault != event.target.value) {
            setToCheck(false)
        }
        else {
            setToCheck(true)
        }
        if (voiceTranStarted) {
            TranscribeClient.stopRecording();
            const currentFromLang = getLanguageCode(fromLang);
            const currentToLang = getLanguageCode(event.target.value);
            voiceInputDisplayedRef.current = textInput.current.value;
            TranscribeClient.startRecordingSpeaker(creds, currentFromLang, currentToLang, withTranscriptionDataReceived);
        }
    };
    //update alert
    const OneDayHours = 86400000;

    if (cookies.updateAlert === undefined) {
        // never show notification
        var update = true
    }
    else {

        if (cookies.dontShowAgain !== undefined) {
        var update = false
        } else {
            if (cookies.alertShowTime === undefined) {
                var update = true
            } else {
                const current = new Date().getTime();
                if (current - cookies.alertShowTime >= OneDayHours) {
                    if (cookies.alertFirstShowTime === undefined) {
                        var update = true
                    } else {
                        if (current - cookies.alertFirstShowTime > (OneDayHours * 7)) {
                            var update = false
                        } else {
                            var update = true
    }
                    }

                } else {
                    var update = false
                }

            }
        }


    }
    const [updateAlertOpen, setUpdateAlertOpen] = React.useState(update);

    const updateAlertClose = () => {
        setUpdateAlertOpen(false);
        setCookie('updateAlert', false, { path: '/', maxAge: 2147483647 })
        const showTime = new Date().getTime();
        setCookie("alertShowTime", showTime, {path: '/', maxAge: 2147483647 })
        if (cookies.alertFirstShowTime === undefined) {
            setCookie('alertFirstShowTime', showTime, {path: '/', maxAge: 2147483647 })
        }
    };

    const dontShowAgain = () => {
        setUpdateAlertOpen(false);
        setCookie('updateAlert', false, {path: '/', maxAge: 2147483647 })
        setCookie('dontShowAgain', true, {path: '/', maxAge: 2147483647 })
    };

    //clear input text
    const textInput = React.useRef(null);
    const textOutput = React.useRef(null);
    const [sourceLang, setSourceLang] = React.useState('');

    const [inputClearActive, setInputClearActive] = React.useState(false);
    const clearInput = () => {
        setInputClearActive(!inputClearActive)
        textInput.current.value = ""
        textOutput.current.value = ""
        setOutputText('')
        displayedString = ""
        voiceInputDisplayedRef.current = ""
    };
    //clear output text
    const [outputClearActive, setOutputClearActive] = React.useState(false);
    const clearOutput = () => {
        setOutputClearActive(!outputClearActive)
        textOutput.current.value = ""
        setOutputText('')
    };

    const clearAllText = () => {
        textInput.current.value = "";
        textOutput.current.value = "";
        setOutputText('');
        setInputText('');
        displayedString = "";
        voiceInputDisplayedRef.current = ""
    }

    //feedback redirect
    const ToTroubleFeedback = () => {
        window.open('https://t.corp.amazon.com/create/templates/e52d3f19-641b-4fdf-8d83-176d739c403e');
    };
    const ToAITFeedback = () => {
        window.open('https://t.corp.amazon.com/create/templates/dd821a8a-44c5-43ce-aceb-e10903a1aee1');
    };
    const ToTranslationFeedback = () => {
        window.open('https://t.corp.amazon.com/create/templates/184e0e5b-5297-4af0-ab6f-27c85f3129d7');
    };
    const ToWikiPage = () => {
        window.open('https://w.amazon.com/bin/view/Amazon_Internal_Translation');
    };

    // const [cred, setCred] = React.useState("")
    // async function getCred(){
    //   await Storage.get('cred.txt', { contentType: 'text/plain', download: true})
    //     .then(result => {result.Body.text().then(string => setCred(string))});
    // };
    // getCred();
    // const creds = {
    //   accessKeyId: cred.split(',')[0],
    //   secretAccessKey: cred.split(',')[1],
    //   // sessionToken: SessionToken
    // };
    const apiName = 'aitportalAPI';
    const path = '/items';
    const myInit = {
        headers: {}
    };
    const [cred, setCred] = React.useState("")
    const [cred2, setCred2] = React.useState("")
    async function getCred() {
        API.get(apiName, path, myInit)
            .then((response) => {
                setCred(response[0]['Value'])
                setCred2(response[1]['Value'])
            })
            .catch((error) => {
                console.log(error)
                console.log(error.response);
            });;
    };
    getCred();
    const creds = {
        accessKeyId: cred,
        secretAccessKey: cred2,
        // sessionToken: SessionToken
    };

    function setDictionaryCookie(name, dictionary) {
        const serializedDict = JSON.stringify(dictionary);
        const encodedDict = encodeURIComponent(serializedDict);
        document.cookie = `${name}=${encodedDict};path=/;expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    }

    function getDictionaryCookie(name) {
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith(name + '='))
            ?.split('=')[1];

        if (!cookieValue) {
            return null; // Return null if the cookie is not found
        }

        const decodedDict = decodeURIComponent(cookieValue);
        return JSON.parse(decodedDict);
    }
    const REGION = "us-east-1";

    createTranslateClientforTranscribe(creds);
    let displayedString = "";
    const voiceInputDisplayedRef = React.useRef(displayedString);

    let translatedPartialString = "";
    const translatedPartialStringRef = React.useRef(translatedPartialString);

    const triggerChangeFrom = () => {
        // Get the input element by its ID
        const element = document.getElementById("filled-multiline-flexible");
        element.scrollTop = element.scrollHeight;
    };

    React.useEffect(() => {
        if (textOutput.current) {
            triggerChangeTo();
        }
        if (textInput.current) {
            triggerChangeFrom();
        }
    }, [voiceInputDisplayedRef.current]);

    const handleScrollTo = () => {

        const element = document.getElementById("filled-multiline-flexible-to");
        //const messagesEndRef = useRef<null | HTMLElement>(element)
        element.scrollIntoView({ block: "end", inline: "nearest" });

    };

    const triggerChangeTo = () => {
        // Get the input element by its ID
        const element = document.getElementById("filled-multiline-flexible-to");
        element.scrollTop = element.scrollHeight;

        const elementFrom = document.getElementById("filled-multiline-flexible");
        elementFrom.scrollTop = elementFrom.scrollHeight;

    };

    const stopRecording = async function () {
        const { stopRecording } = await import("./libs/transcribeClient.js");
        stopRecording();
    };

    const withTranscriptionDataReceived = (data, jobResult) => {

        switch (jobResult) {
            case TranscribeResultType.Begin:
                //just update the text of From text field
                textInput.current.value = textInput.current.value + voiceInputDisplayedRef.current + data;
                break;
            case TranscribeResultType.Finished:
                textInput.current.value = voiceInputDisplayedRef.current + data + "\n";
                // transcribe of current sentence is finished. save from text into voiceInputDisplayedRef
                    voiceInputDisplayedRef.current = textInput.current.value;
                break;
            case TranscribeResultType.Partial:
                    textInput.current.value = voiceInputDisplayedRef.current + data;
                break;
            case TranscribeResultType.TranslateFinished:
                if (translatedPartialStringRef.current === "") {
                    setOutputText(textOutput.current.value + data + "\n");
                } else {
                    setOutputText(translatedPartialStringRef.current + data + "\n");
                    translatedPartialStringRef.current = "";
                }
                break;
            case TranscribeResultType.TranslatePartial:
                if (translatedPartialStringRef.current === "") {
                    translatedPartialStringRef.current = textOutput.current.value;
                }
                // add the translated text into the To text field
                setOutputText(translatedPartialStringRef.current + data);
                triggerChangeTo()
                break;
            default:
                //should not happen
                break;
        }

        triggerChangeFrom();
    }

    const [voiceTranStarted, setVoiceTranStarted] = useState(false);
    const [lastClickTime, setLastClickTime] = useState(null);
    const [interval, setInterval] = useState(null);
    async function startInstantTranslate(e) {

        const currentOS = detectOS();
        if ("MacOS" === currentOS) {
            // alert("Audio instant translation can't be used in MacOS");
            // return;
        }

        if ("Mozilla Firefox" === detectBrowser()) {
            alert("FireFox doesn't support audio instant translation. Please use Chrome or MS Edge");
            return;
        }

        if (fromLang === 'auto') {
            alert("Please select the From language");
            return;
        }

        if (fromLang === toLang) {
            alert("The From lang and To lang are same");
            return;
        }

        if (e.currentTarget.style.backgroundColor === "salmon" && e.currentTarget.style.color === "red") {
            e.currentTarget.style.backgroundColor = "";
            e.currentTarget.style.color = "";
            await stopRecording();
            setVoiceTranLabel('Start Voice Translation (Beta)');
            setVoiceTranStarted(false);
            setMaxRows(defaultMaxRows);

            const stopTime = new Date().getTime();
            if (lastClickTime !== null) {
                const timeInterval = Math.round((stopTime - lastClickTime)/1000);
                setInterval(timeInterval);

                const logsToSend = `{"eventTypeDimension":"translate_live_message","userId":"N/A","userName":"${username}","enterpriseId":"N/A","enterpriseName":"N/A","workspaceId":"N/A","workspaceDomainName":"N/A","channelId":"N/A","channelType":"${timeInterval.toString()}","channelName":"Voice Translation","isSetupPersonalPreference":"N/A","isAutomaticMessageTranslationEnabled":true,"sourceLanguage":"${fromLang}","targetLanguage":"${toLang}","inputCharacterCount":${voiceInputDisplayedRef.current.length},"clickHomepageTab":"N/A"}`;

                console.log("interval is " + timeInterval.toString());
                await sendLogsToCloudWatchLogs(logsToSend)
            }
            return;
        }

        let selectedFromLanguage = getLanguageCode(fromLang);
        if (selectedFromLanguage === undefined) {
            alert("Currently we don't support " + lang[fromLang] + " as \"From\" language for audio instant translation");
            return;
        }

        e.currentTarget.style.backgroundColor = "salmon";
        e.currentTarget.style.color = "red";

        setVoiceTranLabel("Stop Voice Translation");
        setVoiceTranStarted(true);
        setMaxRows(maxRowsduringVoice);

        let credLocal = undefined;

        if (creds.accessKeyId === '') {
            const credData = await API.get(apiName, '/items', myInit);
            credLocal = {
                accessKeyId: credData[0]['Value'],
                secretAccessKey: credData[1]['Value']
                // sessionToken: SessionToken
            };
            createTranslateClientforTranscribe(credLocal);
        } else {
            credLocal = creds;
        }

        const startLog = `{"eventTypeDimension":"translate_live_message","userId":"N/A","userName":"${username}","enterpriseId":"N/A","enterpriseName":"N/A","workspaceId":"N/A","workspaceDomainName":"N/A","channelId":"N/A","channelType":"1","channelName":"Voice Translation","isSetupPersonalPreference":"N/A","isAutomaticMessageTranslationEnabled":true,"sourceLanguage":"${fromLang}","targetLanguage":"${toLang}","inputCharacterCount":${voiceInputDisplayedRef.current.length},"clickHomepageTab":"N/A"}`;
        await sendLogsToCloudWatchLogs(startLog)

        voiceInputDisplayedRef.current= displayedString;
        translatedPartialStringRef.current = translatedPartialString;
        const now = new Date().getTime();
        setLastClickTime(now);
        clearAllText();
        try {
            await TranscribeClient.startRecordingSpeaker(credLocal, selectedFromLanguage, toLang, withTranscriptionDataReceived);
        } catch(error) {
            if (e.currentTarget.style.backgroundColor === "salmon" && e.currentTarget.style.color === "red") {
                e.currentTarget.style.backgroundColor = "";
                e.currentTarget.style.color = "";
            }
            alert("An error occurred while voice translation: " + error.message + " Please restart the voice translation.");
            const logs = `{"Error": "${error.message}", "Username": "${username}", "FromLang": "${fromLang}", "ToLang": "${toLang}"}`
            await sendLogsToCloudWatchLogs(logs, translatorLogGroupName, voiceErrorLogStreamName);
            await stopRecording();
        }

    };




    let fromHist = getDictionaryCookie('fromHist')
    let toHist = getDictionaryCookie('toHist')
    function checkHist(hist) {
        if (hist === undefined) {
            return false;
        } else return hist !== null;
    }
    const [buttonVisible, setButtonVisible] = useState(false);
    function checkInstantTranslationUsable(userName) {
        return allowedList.indexOf(userName) >= 0;
    }
    const InstantTranslationText = "This is a beta feature can be used to translate the audio transcribe to the target language."
    const [voiceTranLabel, setVoiceTranLabel] = useState('Start Voice Translation (Beta)');

    const lang = {
        "af": "Afrikaans",
        "sq": "Albanian",
        "am": "Amharic",
        "ar": "Arabic",
        "hy": "Armenian",
        "az": "Azerbaijani",
        "bn": "Bengali",
        "bs": "Bosnian",
        "bg": "Bulgarian",
        "ca": "Catalan",
        "zh": "Chinese (Simplified)",
        "zh-TW": "Chinese (Traditional)",
        "hr": "Croatian",
        "cs": "Czech",
        "da": "Danish",
        "fa-AF": "Dari",
        "nl": "Dutch",
        "en": "English",
        "et": "Estonian",
        "fa": "Farsi (Persian)",
        "tl": "Filipino, Tagalog",
        "fi": "Finnish",
        "fr": "French",
        "fr-CA": "French (Canada)",
        "ka": "Georgian",
        "de": "German",
        "el": "Greek",
        "gu": "Gujarati",
        "ht": "Haitian Creole",
        "ha": "Hausa",
        "he": "Hebrew",
        "hi": "Hindi",
        "hu": "Hungarian",
        "is": "Icelandic",
        "id": "Indonesian",
        "ga": "Irish",
        "it": "Italian",
        "ja": "Japanese",
        "kn": "Kannada",
        "kk": "Kazakh",
        "ko": "Korean",
        "lv": "Latvian",
        "lt": "Lithuanian",
        "mk": "Macedonian",
        "ms": "Malay",
        "ml": "Malayalam",
        "mt": "Maltese",
        "mr": "Marathi",
        "mn": "Mongolian",
        "no": "Norwegian (Bokmål)",
        "ps": "Pashto",
        "pl": "Polish",
        "pt": "Portuguese (Brazil)",
        "pt-PT": "Portuguese (Portugal)",
        "pa": "Punjabi",
        "ro": "Romanian",
        "ru": "Russian",
        "sr": "Serbian",
        "si": "Sinhala",
        "sk": "Slovak",
        "sl": "Slovenian",
        "so": "Somali",
        "es": "Spanish",
        "es-MX": "Spanish (Mexico)",
        "sw": "Swahili",
        "sv": "Swedish",
        "ta": "Tamil",
        "te": "Telugu",
        "th": "Thai",
        "tr": "Turkish",
        "uk": "Ukrainian",
        "ur": "Urdu",
        "uz": "Uzbek",
        "vi": "Vietnamese",
        "cy": "Welsh"
    }
    const defaultMaxRows = 80;
    const maxRowsduringVoice = 10;
    const [maxRows, setMaxRows] = useState(defaultMaxRows); // Starting with a default value
    let fromOther = Object.keys(lang).filter(key => !(fromHist && key in fromHist))
    .reduce((acc, key) => {
      acc[key] = lang[key];
      return acc;
    }, {});

    let toOther = Object.keys(lang).filter(key => !(toHist && key in toHist))
    .reduce((acc, key) => {
      acc[key] = lang[key];
      return acc;
    }, {});

    const AWS = require('aws-sdk');

    AWS.config.update({
        accessKeyId: cred,
        secretAccessKey: cred2,
        region: REGION
    });

    const cloudwatchlogs = new AWS.CloudWatchLogs();

    const defaultLogGroupName = 'translatorCustomized';
    const defaultLogStreamName = 'liveTranslate';

    const translatorLogGroupName = 'translatorProd'
    const voiceErrorLogStreamName = 'voiceError';

    async function sendLogsToCloudWatchLogs(logs,logGroupName = defaultLogGroupName, logStreamName = defaultLogStreamName) {
        const logEvents = [{ message: logs, timestamp: new Date().getTime() }]
        // Put log events
        const putLogEventsParams = {
            logGroupName,
            logStreamName,
            logEvents,
        };
        await cloudwatchlogs.putLogEvents(putLogEventsParams).promise();
    }

    const client = new TranslateClient({ region: REGION, credentials: creds });
    const [lengthText, setlengthText] = React.useState("")

    let timeoutId

    async function translate(text) {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(async () => {
            setInputText(text);
            if (text.target !== undefined) {
                setlengthText(text.target.value);
            }
            var params
            params = {
                SourceLanguageCode: fromLang, /* required */
                TargetLanguageCode: toLang, /* required */
                Text: document.getElementById('filled-multiline-flexible').value, /* required */
                Settings: {
                    Formality: "FORMAL",
                    Profanity: "MASK"
                },
                TerminologyNames: [
                    'en_jp_softlines_dictionary',
                    /* more items */
                ]
            };
            const command = new TranslateTextCommand(params);
            console.log("send the translation command");
            await client.send(command)
                .then(response => {
                    setSourceLang(response.SourceLanguageCode); setOutputText(response.TranslatedText);
                    console.log("get the translated response");
                    const logsToSend = `{"eventTypeDimension":"translate_live_message","userId":"N/A","userName":"${username}","enterpriseId":"N/A","enterpriseName":"N/A","workspaceId":"N/A","workspaceDomainName":"N/A","channelId":"N/A","channelType":"N/A","channelName":"N/A","isSetupPersonalPreference":"N/A","isAutomaticMessageTranslationEnabled":true,"sourceLanguage":"${response.SourceLanguageCode}","targetLanguage":"${toLang}","inputCharacterCount":${text.target.value.length},"clickHomepageTab":"N/A"}`;
                    sendLogsToCloudWatchLogs(logsToSend);
                    setCookie('fromLast', response.SourceLanguageCode, { path: '/', maxAge: 2147483647 })
                    setCookie('toLast', toLang, { path: '/', maxAge: 2147483647 })
                    if (fromHist == undefined) {
                        const fromHistTemp = {}
                        fromHistTemp[response.SourceLanguageCode] = 1
                        setDictionaryCookie('fromHist', fromHistTemp)
                    }
                    else {
                        if (fromHist[response.SourceLanguageCode] === undefined) {
                            fromHist[response.SourceLanguageCode] = 1
                            setDictionaryCookie('fromHist', fromHist)
                        } else {
                            fromHist[response.SourceLanguageCode] += fromHist[response.SourceLanguageCode]

                            setDictionaryCookie('fromHist', fromHist)
                        }
                    }

                    if (toHist == undefined) {
                        const toHistTemp = {}
                        toHistTemp[toLang] = 1
                        setDictionaryCookie('toHist', toHistTemp)

                    }
                    else {
                        if (toHist[toLang] === undefined) {
                            toHist[toLang] = 1
                            setDictionaryCookie('toHist', toHist)
                        } else {
                            toHist[toLang] += toHist[response.SourceLanguageCode]

                            setDictionaryCookie('toHist', toHist)
                        }
                    }
                }
                ).catch(reason => {
                    console.log("meet error on live translate:" + reason.toString());
                });
        }, 500)



    }

    var showSourceLang = ''
    if (typeof lang[sourceLang] != 'undefined') {
        showSourceLang = "- " + lang[sourceLang]
    }

    React.useEffect(() => { translate(InputText) }, [toLang, fromLang]);

    //Swap button action
    const [swapActive, setSwapActive] = React.useState(false);
    const swapAction = () => {
        const tmpFromLang = fromLang;
        const tmpTolang = toLang;
        setSwapActive(!swapActive);
        let hold = textInput.current.value;
        textInput.current.value = textOutput.current.value;
        textOutput.current.value = hold;

        hold = fromLang;
        setFromLang(toLang);
        setToLang(hold);

        if (voiceTranStarted) {
            TranscribeClient.stopRecording();
            let currentFromLang;
            if (tmpFromLang === fromLang) {
                currentFromLang = getLanguageCode(toLang);
            } else {
                currentFromLang = getLanguageCode(fromLang)
            }
            let currentToLang;
            if (tmpTolang === toLang) {
                currentToLang = getLanguageCode(tmpFromLang)
            } else {
                currentToLang = getLanguageCode(toLang);
            }
            TranscribeClient.startRecordingSpeaker(creds, currentFromLang, currentToLang, withTranscriptionDataReceived);
        }
    };

    const handleImageClick = () => {
        // https://skb.highcastle.a2z.com/implementations/219
        window.open('https://slack.com/app_redirect?app=A06A2TC3NFQ', '_blank', 'noopener, noreferrer');
	};

    //Copy button action
    const handleCopy = () => {
        navigator.clipboard.writeText(OutputText).then(() => {
            alert('Translated text copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };


    //textfield color change
    const style = {
        m: 1, width: '500px', background: '#eaeaea',
        "& label.Mui-focused": {
            color: "#000000"
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#ff9900"
            }
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: "#ff9900"
        },
    }
    if (username) {
        // User is authenticated, token are valids.
        return (
            <div className="awsui">
                <Navbar variant="dark" fixed="top" className="nav-color">
                    <Container fluid>
                        <Navbar.Brand href="https://sli.translator.amazon.dev">
                            <img alt="" src={logo} width="180" height="40" className="d-inline-block align-items-center" />{' '}
                        </Navbar.Brand>
                        {/* <Navbar.Toggle aria-controls="navbarScroll" className="bg-info h-25 d-flex align-items-center justify-content-center"/> */}
                        <Navbar.Collapse className="justify-content-end">
                            <Navbar.Text className="d-inline-block align-items-center">&nbsp; Welcome &nbsp;<a href='https://phonetool.amazon.com/users/' onClick="location.href=this.href+username;return false;">{username}</a>&nbsp;</Navbar.Text>
                            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                <Tooltip title="Account settings">
                                    <IconButton
                                        onClick={userPictureClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <Avatar sx={{ width: 42, height: 42 }} src={userPicture} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={userPictureClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem>
                                    <DarkMode />
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                    <ListItemIcon>
                                        <InfoIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText onClick={ToWikiPage}>About</ListItemText>
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                    <ListItemText>Submit Feedback</ListItemText>
                                </MenuItem>
                                <MenuItem>
                                    <ListItemIcon>
                                        <SendIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText onClick={ToTroubleFeedback}>Trouble Ticket</ListItemText>
                                </MenuItem>
                                <MenuItem>
                                    <ListItemIcon>
                                        <SendIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText onClick={ToAITFeedback}>Feature Request</ListItemText>
                                </MenuItem>
                                <MenuItem>
                                    <ListItemIcon>
                                        <SendIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText onClick={ToTranslationFeedback}>Translation Quality</ListItemText>
                                </MenuItem>
                            </Menu>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <div>
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackClose}
                        action={snackAction}
                    >
                        <Alert onClose={handleSnackClose} severity={snackSeverity}>
                            {snackMessage}
                        </Alert>
                    </Snackbar>
                    <Fab sx={fabStyle} color="primary" aria-label="send" variant='circular' onClick={userFabClick}
                        aria-controls={fabOpen ? 'fab-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={fabOpen ? 'true' : undefined}>
                        {chat}
                    </Fab>
                    <Menu
                        anchorEl={fabAnchor}
                        id="fab-menu"
                        open={fabOpen}
                        onClose={userFabClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                borderRadius: '10px',
                                width: '300px',
                                height: '500px',
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: '-55px',
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    mt: 50,
                                    top: 0,
                                    right: 20,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <ThemeProvider theme={theme}>
                            <TextField
                                disabled
                                id="input-with-icon-textfield"
                                defaultValue="Send us feedback anytime"
                                margin="dense"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MailOutlineRoundedIcon color='submit' sx={{verticalAlign: 'middle'}}/>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    m: 2,
                                    width: 283,
                                    '& .MuiInputBase-root.Mui-disabled': {
                                        color: 'red', // Example color
                                        '& .MuiInputBase-input': {
                                            fontSize: '14px',
                                        },
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                    },
                                }}
                                variant="standard"
                            />

                            <div>
                                <Typography gutterBottom sx={{
                                    m: 1,
                                    width: 283,
                                    fontSize: "12px",
                                    color : "black",
                                    '& .MuiInputBase-root.Mui-disabled': {
                                        color: 'red', // Example color
                                        '& .MuiInputBase-input': {
                                            fontSize: '12px',
                                        },
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#0000FF",
                                    },
                                }}>
                                    Report an issue:
                                    <div><a href="https://t.corp.amazon.com/create/templates/e52d3f19-641b-4fdf-8d83-176d739c403e">Trouble
                                        Ticket</a></div>

                                </Typography>

                            </div>


                            <Typography gutterBottom margin="dense" sx={{
                                m: 1,
                                width: 283,
                                fontSize: "12px",
                                color : "black",
                                '& .MuiInputBase-root.Mui-disabled': {
                                    color: 'red', // Example color
                                    '& .MuiInputBase-input': {
                                        fontSize: '12px',
                                    },
                                },
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#0000FF",
                                },
                            }}>
                                Submit Feature Request:
                                <div><a href="https://t.corp.amazon.com/create/templates/dd821a8a-44c5-43ce-aceb-e10903a1aee1">Feature
                                    Request</a>
                                </div>

                            </Typography>

                            <Typography gutterBottom margin="dense" sx={{
                                m: 1,
                                width: 283,
                                fontSize: "12px",
                                color: "black",
                                '& .MuiInputBase-root.Mui-disabled': {
                                    color: 'red', // Example color
                                    '& .MuiInputBase-input': {
                                        fontSize: '12px',
                                    },
                                },
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#0000FF",
                                },
                            }}>
                                Slack Channel:
                                <div>
                                    <a href="https://amazon.enterprise.slack.com/archives/C075ABWSRU1">#amazon-internal-translation-interest</a>
                                </div>

                            </Typography>


                            <TextField
                                id="outlined-multiline-static"
                                label="You can also leave a message here"
                                variant='filled'
                                multiline
                                rows={8}
                                margin="dense"
                                sx={{ m: 1, width: 283, mt: 1, '& .MuiInputBase-input': {
                                        fontSize: '12px', // Set the font size for the input
                                    }
                                }}
                                placeholder="If you’d like to provide quick feedback, please send us a note here. We monitor this channel, but won’t reach out directly. If you’d like to get in touch with the team, please contact us via one of the links above. "
                                onKeyDown={e => {
                                    e.stopPropagation()
                                }}
                                onChange={e => setMessageInput(e.target.value)}
                            />
                            <Button variant="text" color="submit" sx={{ml: 28}} onClick={sendMessage}>Submit</Button>
                        </ThemeProvider>
                    </Menu>
                </div>

                <div style={{textAlign: "center", marginTop: "100px"}}>
                    <FormControl variant='filled' sx={{m: 1, left: 32, minWidth: 200}} size="small">
                        <InputLabel variant='filled' id="demo-simple-select-autowidth-label">From</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={fromLang}
                            onChange={FromLanghandleChange}
                            autoWidth
                            label="From"
                            sx={{
                                background: '#eeeeee',
                                "&.MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#ff9900"
                                    }
                                }
                            }}
                        >
                            <MenuItem value={'auto'}>Auto Detect {showSourceLang}</MenuItem>
                            <MenuItem value={'en'}>English</MenuItem>
                            <MenuItem value={'ja'}>Japanese</MenuItem>
                            {checkHist(fromHist) &&
                                <Divider><b>History</b></Divider>


                            }
                            {checkHist(fromHist) && Object.entries(fromHist).map(([lang_code, cnt]) => (
                                <MenuItem key={lang_code} value={lang_code}>
                                    <ListItemText primary={lang[lang_code]}/>
                                </MenuItem>
                            ))}
                            <Divider><b>All Languages</b></ Divider>
                            {Object.entries(fromOther).map(([lang_code, language]) => (
                                <MenuItem key={lang_code} value={lang_code}>
                                    <ListItemText primary={language}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, mr: 38}}>
                        <Checkbox sx={{
                            mt: 2, left: 15, color: pink[500],
                            '&.Mui-checked': {
                                color: pink[500],
                            }
                        }} icon={<FavoriteBorder/>} checkedIcon={<Favorite/>}
                                  checked={fromcheck}
                                  onChange={defaultFromChange}
                        />
                    </FormControl>
                    <FormControl variant='filled' sx={{m: 1, left: 20, minWidth: 200}} size="small">
                        <InputLabel variant='filled' id="demo-simple-select-autowidth-label">To</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={toLang}
                            onChange={ToLanghandleChange}
                            autoWidth
                            label="To"
                            sx={{
                                background: '#eeeeee',
                                "&.MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#ff9900"
                                    }
                                }
                            }}
                        >
                            <MenuItem value={'en'}>English</MenuItem>
                            <MenuItem value={'ja'}>Japanese</MenuItem>
                            {
                                checkHist(toHist) &&
                                <Divider><b>History</b></Divider>
                            }
                            {checkHist(toHist) && Object.entries(toHist).map(([lang_code, cnt]) => (
                                <MenuItem key={lang_code} value={lang_code}>
                                    <ListItemText primary={lang[lang_code]}/>
                                </MenuItem>
                            ))}
                            <Divider><b>All Languages</b></Divider>
                            {Object.entries(toOther).map(([lang_code, language]) => (
                                <MenuItem key={lang_code} value={lang_code}>
                                    <ListItemText primary={language}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, mr: 38}}>
                        <Checkbox sx={{
                            mt: 2, left: 3, color: pink[500],
                            '&.Mui-checked': {
                                color: pink[500],
                            }
                        }} icon={<FavoriteBorder/>} checkedIcon={<Favorite/>}
                                  checked={tocheck}
                                  onChange={defaultToChange}
                        />
                    </FormControl>
                    <div>
                        <ThemeProvider theme={theme}>
                            <TextField
                                id="filled-multiline-flexible"
                                label="From"
                                multiline
                                minRows={10}
                                //rows={10}
                                maxRows={maxRows}
                                onChange={translate}
                                variant="filled"
                                // sx={{ m: 1, width: '500px' , background: '#eaeaea'}}
                                sx={style}
                                inputRef={textInput}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end" sx={{
                                            "& .MuiIconButton-root": {
                                                position: "absolute",
                                                top: 5,
                                                right: 15
                                            }
                                        }}>
                                            <IconButton edge="end" color="default" onClick={clearInput}
                                                        component={motion.div}
                                                        animate={{
                                                            rotate: inputClearActive ? 90 : 0
                                                        }}>
                                                <ClearIcon fontSize="small"/>
                                            </IconButton>
                                            <p style={{bottom: -10, right: 5, position: "absolute"}}>
                                                <small>{lengthText.length}/5000</small></p>
                                        </InputAdornment>
                                }}
                            />
                        </ThemeProvider>
                        <IconButton sx={{mt: 15, color: '#ff9900'}} component={motion.div} onClick={swapAction}
                                    animate={{
                                        rotate: swapActive ? 180 : 0
                                    }}>
                            <SwapHorizIcon className="swap-icon"/>
                        </IconButton>
                        <ThemeProvider theme={theme}>
                            <Draggable disabled={!voiceTranStarted}>
                                <TextField
                                    id="filled-multiline-flexible-to"
                                    label="To"
                                    multiline
                                    minRows={10}
                                    //rows={10}
                                    maxRows={maxRows}
                                    value={OutputText}
                                    onChange={(e) => handleScrollTo()}
                                    variant="filled"
                                    // sx={{ m: 1, width: '500px', background: '#eaeaea'}}
                                    sx={style}
                                    inputRef={textOutput}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="end"
                                                            sx={{position: 'absolute', bottom: 15, right: 5}}>
                                                <IconButton onClick={handleCopy} size="small">
                                                    <small>Copy</small><ContentCopyIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),


                                        endAdornment:
                                            <InputAdornment position="end" sx={{
                                                "& .MuiIconButton-root": {
                                                    position: "absolute",
                                                    top: 5,
                                                    right: 15
                                                }
                                            }}>
                                                <IconButton edge="end" color="default" onClick={clearOutput}
                                                            component={motion.div}
                                                            animate={{
                                                                rotate: outputClearActive ? 90 : 0
                                                            }}>
                                                    <ClearIcon fontSize="small"/>
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                            </Draggable>
                        </ThemeProvider>
                    </div>

                    <div>

                        <ThemeProvider theme={theme}>
                            {buttonVisible && (
                                <Tooltip title={InstantTranslationText}>

                                    <Button variant="outlined" startIcon={<VolumeUpIcon/>} color='upload'
                                            onClick={(e) => startInstantTranslate(e)}
                                            style={{textTransform: 'none'}}> {voiceTranLabel} </Button>
                                </Tooltip>)}
                            {buttonVisible && isWindows && (
                                <HowtoUseDialog></HowtoUseDialog>

                            )}
                            {buttonVisible && (!isWindows) && (
                                <HowtoUseDialogMacOS></HowtoUseDialogMacOS>

                            )}
                        </ThemeProvider>
                    </div>

                    <p><small>Powered by <a href='https://aws.amazon.com/translate/' target="_blank">Amazon
                        Translate</a></small></p>

                    <p style={{textAlign: "center"}}>
                        We are <span style={{color: "red", fontSize: "bold"}}>RED Certified</span> and support <u>Restricted
                        and Critical</u> data.<br/>
                    </p>

                    <p><small>If you have any issues, please contact us <a
                        href='https://t.corp.amazon.com/create/templates/e52d3f19-641b-4fdf-8d83-176d739c403e'
                        target="_blank">here</a></small></p>
                </div>
                <div style={{textAlign: "center", paddingTop: "10px"}}>
                    <div style={{display: "inline-block"}}></div>
                </div>
                <p style={{textAlign: "center"}}>Upload .docx, .pptx or .xslx file to translate (Up to 20MB or 1 million
                    characters)</p>


                <div className='uploadingfile' style={{
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: "center",
                    paddingTop: "10px"
                }}>
                    <input id="file-upload" type="file" hidden accept="txt/csv" onChange={(e) => onFileChange(e)}/>
                    <label htmlFor="file-upload">
                        <ThemeProvider theme={theme}>
                            <Tooltip title="Highly Confidential data supported" placement="top">
                                <Button component="span" variant="outlined" startIcon={<AttachFileIcon />} style={{ textTransform: 'none' }}> Choose File </Button>
                            </Tooltip>
                        </ThemeProvider>
                    </label>
                    <p>&nbsp; &nbsp; &nbsp;{fileName}&nbsp; &nbsp; &nbsp;</p>
                    <ThemeProvider theme={theme}>
                        <Button variant="outlined" startIcon={<CloudUploadIcon />} color='upload' onClick={(e) => onFileUpload(e)} style={{ textTransform: 'none' }}> Upload </Button>
                    </ThemeProvider>
                </div>
                <p style={{ textAlign: "center", paddingTop: "20px" }}>{progress}</p>
                <p style={{ textAlign: "center", paddingTop: "20px", color: "red", whiteSpace: "pre" }}>{uploadResponse}</p>
                <p style={{ textAlign: "center", paddingTop: "20px", color: "green", whiteSpace: "pre" }}>{uploadSuccess}</p>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Alert</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>There is a file with a same name. Do you want to overwrite?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="warning" onClick={overwriteFile}>
                            Overwrite
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={Deleteshow} onHide={handleDeleteClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Alert</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this file?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleDeleteClose}>
                            Close
                        </Button>
                        <Button variant="danger" onClick={deleteFile}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Dialog
                    open={updateAlertOpen}
                    onClose={updateAlertClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
                        {"AIT is now available at Slack"}
                        <br></br>

                        <img
                            style={{maxWidth: "50%", maxHeight: "calc(50vh - 32px)", width: "auto", height: "auto"}}
                            src={ait_logo}
                            alt="image"
                        />


                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText sx={{color: 'black'}}>
                            We have launched AIT Slack app, enabling all employees to communicate securely with instant
                            translation for private messages, channels, and other features in 75 languages.
                            <br></br>

                            Please check it out <Button href="https://slack.com/app_redirect?app=A06A2TC3NFQ" target="_blank" variant="contained" color="primary">
                            HERE
                        </Button>
                            <br></br>
                            <br></br>
                        </DialogContentText>

                        <img
                            style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
                            src={update_image}
                            alt="image"
                        />

                        <DialogContentText sx={{color: 'black'}}>
                            There are four primary features within the AIT Slack app. Check out <Link
                            href="https://w.amazon.com/bin/view/Amazon_Internal_Translation/AITTranslatorSlackApp/"
                            target="_blank">User Guide</Link> for the
                            details of each feature and see below for a quick start guide.
                            <br></br>
                        </DialogContentText>
                        <DialogContentText sx={{color: 'black'}}>
                            • <span style={{ fontWeight: 'bold' }}>Personal translation</span> – translate anything you want by messaging AIT directly
                        </DialogContentText>
                        <DialogContentText sx={{color: 'black'}}>
                            • <span style={{ fontWeight: 'bold' }}>Translate selected messages</span> – translate specific messages anytime from “More actions” key
                        </DialogContentText>
                        <DialogContentText sx={{color: 'black'}}>
                            • <span style={{ fontWeight: 'bold' }}>Translation on send</span> – message senders can translate specific messages when sending by
                            using “ /ait ” command
                        </DialogContentText>
                        <DialogContentText sx={{color: 'black'}}>
                            • <span style={{ fontWeight: 'bold' }}>Private message and channel translation</span> – invite AIT to a private chat or channel and
                            translate all messages
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={dontShowAgain}>Don't show it again</Button>
                        <Button onClick={updateAlertClose}>OK</Button>
                    </DialogActions>
                </Dialog>

                <div className="custom-slack-image" onClick={handleImageClick}>
                    <img src={slackImage} alt="Clickable" className="slack-image"/>
            </div>
            </div>
        );
    } else {
        return (
            <div className="awsui">
                <p className="awsui-util-t-c">
                    Loading...
                </p>
                <CircularProgress size='2rem'
                    sx={{ color: '#ff9900' }} />
            </div>
        );
    }
}

export default App;
